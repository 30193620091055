import './App.css'
import Rutas from './routes/Rutas/Rutas'

function App () {
  return (
    <div>
      <Rutas />
    </div>
  )
}

export default App
